'use strict';

$(document).ready(function () {
    var banners = document.querySelectorAll('.image-heading-container');

    for (let index = 0; index < banners.length; index++) {
        const banner = banners[index];
        var position = $(banner).data('heightposition');

        if ($(window).width() < 992) {
            position = $(banner).data('heightpositionmobile');
        }

        if (position === 'top') {
            $(banner).addClass('image-text-top-position ');
        } else if (position === 'bottom') {
            $(banner).addClass('image-text-bottom-position');
        }
    }
});
